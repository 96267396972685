import "./location-peru.scss"

import { graphql } from 'gatsby'
import * as React from "react"
import FindDreamJob from "../../components/careers/finddreamjob"
import JobOpenings from "../../components/careers/jobopenings"
import LocationHeader from "../../components/careers/locationheader"
import WhyBTSLocations from "../../components/careers/whybtslocations"
import Layout from "../../components/layout/layout"
import Seo from "../../components/seo"

export const query = graphql`
 query($language: String!) {
    allContentfulJobs(
      filter: { locations: { elemMatch: { country: { slug: { eq: "peru" }, node_locale: { eq: "en-US" }}}}}
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        id
        jobSlug
        title
        updatedAt(formatString: "MM/DD/YY")
        remote
        office
        urgent
        description {
          description
        }
        locations {
          id
          slug
          name
          shortName
        }
      }
    }
 locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

const LocationPeruPage = ({ data }) => {
    const pageTitle = "Careers - Peru"
    const pageDescription = "Get to Know Our Peru Offices"
    const lang = 'EN'
    const meta = []

    const lima = {
        name: "Lima",
        text: "Lima, the capital and largest city of Peru, is the cultural, financial and industrial center of the country. Located along the Pacific coast, Lima is a very vibrant city is also known for its gastronomy, and Peruvian cuisine has long gained international recognition. Not to mention that Peru can trace its heritage back to the 10th millennium BCE."
    }

    const breadcrumbMap = [
        { title: "Careers", url: "/careers/" },
        { title: "Locations", url: "/careers/locations/" },
        { title: "Peru", url: "#" }
    ]

    const location = {
        title: "Peru",
        cities: ["Lima"],
        sectionClassName: "section-location-peru",
        modalClassName: "modal-location-peru"
    }

    var {
        nodes: jobs
    } = data.allContentfulJobs;

    const jobsList = jobs.map(({ jobSlug, title, locations, description, remote, relocation, office, urgent }) => (
        {
            title: title,
            location: locations.map((item) => (item["shortName"])).join(" / "),
            url: "/careers/jobs/" + jobSlug + "/",
            urgent: urgent,
            tags: { "remote": remote, "office": office, "relocation": relocation },
            introduction: description ? description.description.slice(0, 220) + "..." : ""
        }
    ));

    return (
        <>
            <Layout breadcrumbMap={breadcrumbMap}>
                <Seo title={pageTitle} description={pageDescription} lang={lang} meta={meta} />
                <div className="max-container-pages">
                    <LocationHeader location={location} city={lima.name}>
                        <span className="city-title">{lima.name}</span>
                        <p className="city-text">
                            {lima.text}
                        </p>
                    </LocationHeader>
                    <WhyBTSLocations reverse={true}></WhyBTSLocations>
                    <JobOpenings jobs={jobsList} search="Peru"></JobOpenings>
                    <FindDreamJob></FindDreamJob>
                </div>
            </Layout>
        </>
    )
}

export default LocationPeruPage
